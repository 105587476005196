import React, { FC, ReactNode, Fragment } from 'react';

import classnames from 'classnames';

type Props = {
    children?: JSX.Element;
    depth?: number;
    className?: string;
    gradient?: boolean;
};

type CircleProps = {
    children: JSX.Element;
    index: number;
};

interface NestedCirclesProps {
    depth: number;
    children: ReactNode;
}

const Circle: FC<CircleProps> = ({ children, index}) => (
    <div className={`circle-${index}`}>{children}</div>
);

const NestedCircles: FC<NestedCirclesProps> = ({ depth, children }) => {
    if (depth <= 0) {
        return <Fragment>{children}</Fragment>;
    }

    return (
        <Circle index={depth}>
            <NestedCircles depth={depth - 1}>{children}</NestedCircles>
        </Circle>
    );
};

const NestedCirclesGradient: FC<NestedCirclesProps> = ({ depth, children }) => {
    const circles = Array.from({ length: depth }, (_, i) => `circle-${i + 1}`);

    return circles.reduceRight(
        (acc, curr) => (
            <div className={curr}>
                <div className={`${curr}-1`}>{acc}</div>
            </div>
        ),
        <Fragment>{children}</Fragment>,
    );
};

const Circles: FC<Props> = ({ children, className, depth = 5, gradient = true }) => {
    return (
        <div className={classnames('circles', className)}>
            {gradient ? (
                <NestedCirclesGradient depth={5}>{children}</NestedCirclesGradient>
            ) : (
                <NestedCircles depth={depth}>{children}</NestedCircles>
            )}
        </div>
    );
};

export default Circles;
