/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';

import classNames from 'classnames';

import Icon from '@src/components/common/Icon';
import Button from '@src/components/common/Button';
import Banner from '@components/common/Banner';

import useCart from '@src/hooks/useCart';

const Header: FC = () => {
    const body = useRef<HTMLElement>();

    const { toggleCart } = useCart();

    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleCartClick = (): void => {
        toggleCart();
    };

    const toggleMenu = (
        e: React.MouseEvent<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>,
    ): void => {
        e.stopPropagation();

        if (isMobile) {
            setIsOpen(prevIsOpen => {
                if (prevIsOpen) {
                    body?.current?.classList.remove('modal-open');

                    return !prevIsOpen;
                }

                body?.current?.classList.add('modal-open');

                return !prevIsOpen;
            });
        }
    };

    const handleLogoClick = (): void => {
        setIsOpen(false);
        body?.current?.classList.remove('modal-open');
    };

    useEffect(() => {
        body.current = document.body;
        body.current.classList.remove('modal-open');

        if (window.innerWidth < 768) {
            setIsMobile(true);
        }

        const handleResize = (): void => {
            if (window.innerWidth > 768) {
                body?.current?.classList.remove('modal-open');
                setIsOpen(false);
                setIsMobile(false);
            } else {
                setIsMobile(true);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            body?.current?.classList.remove('modal-open');
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Fragment>
            <header className={classNames('header-container', { open: isOpen })}>
                <nav className='header'>
                    <div className='nav-btn-container'>
                        <button className='nav-btn' type='button' onClick={toggleMenu}>
                            <span />
                            <span />
                        </button>
                    </div>
                    <div className='links'>
                        <Link to='/shop' className='nav-link' onClick={toggleMenu}>
                            Products
                        </Link>
                        <Link to='/about-us' className='nav-link' onClick={toggleMenu}>
                            About Us
                        </Link>
                        <Link to='/faqs' className='nav-link' onClick={toggleMenu}>
                            FAQS
                        </Link>
                    </div>
                    <Link to='/' className='logo' onClick={handleLogoClick}>
                        <Icon name='logo' width={180} height={37} />
                    </Link>
                    <div className='buttons'>
                        <Button color='link' icon='cart' onClick={handleCartClick}>
                            Cart
                        </Button>
                        {/* <Button color='link' icon='profile'> */}
                        {/*    My Account */}
                        {/* </Button> */}
                    </div>
                </nav>
                <Banner />
            </header>

            <div className='header-overlay' onClick={toggleMenu} />
        </Fragment>
    );
};

export default Header;
