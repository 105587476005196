import React, { FC } from 'react';

import Circles from '@components/common/Circles';

const CirclesContainer: FC = () => {
    return (
        <div className='circles-container'>
            <Circles className='left-circle' depth={6} gradient={false} />

            <Circles className='right-circle' depth={6} gradient={false} />
        </div>
    );
};

export default CirclesContainer;
