import React, { FC, memo, ReactNode, useState } from 'react';

import { CartContext } from '@src/hooks/useCart';

type Props = {
    children: ReactNode;
};

const CartProvider: FC<Props> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return <CartContext.Provider value={{ isOpen, setIsOpen }}>{children}</CartContext.Provider>;
};

export default memo<FC<Props>>(CartProvider);
