import React, { FC, PropsWithChildren } from 'react';

import { PageProps } from 'gatsby';

import CartProvider from '@src/providers/CartProvider';

import Footer from '@src/layouts/Footer';
import Header from '@src/layouts/Header';

import Cart from '@components/cart';

import CirclesContainer from '@components/sections/CirclesContainer';

type Props = PropsWithChildren & PageProps;

const Layout: FC<Props> = ({ children, location }) => {
    if (location.pathname.includes('/checkout')) {
        return <main className='checkout-page-container'>{children}</main>;
    }

    return (
        <React.Fragment>
            <CartProvider>
                <Header />
                <Cart />

                <main>
                    {children}
                    <CirclesContainer />
                </main>

                <Footer />
            </CartProvider>
        </React.Fragment>
    );
};

export default Layout;
