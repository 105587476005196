import React, { SVGProps } from 'react';

import ArrowLeftIcon from '@assets/svg/common/arrow-left.svg';
import ArrowRightIcon from '@assets/svg/common/arrow-right.svg';
import CartIcon from '@assets/svg/header/cart.svg';
import ChevronIcon from '@assets/svg/common/chevron.svg';
import CrossIcon from '@assets/svg/common/cross.svg';
import CustomerServiceIcon from '@assets/svg/checkout/customer-service.svg';
import FastShipIcon from '@assets/svg/checkout/fast-ship.svg';
import GuaranteeIcon from '@assets/svg/checkout/guarantee.svg';
import InputArrowRightIcon from '@assets/svg/common/input-arrow-right.svg';
import LogoIcon from '@assets/svg/common/logo.svg';
import MinusIcon from '@assets/svg/common/minus.svg';
import PawIcon from '@assets/svg/common/paw.svg';
import PlusIcon from '@assets/svg/common/plus.svg';
import ProfileIcon from '@assets/svg/header/profile.svg';
import QualityAssuredIcon from '@assets/svg/checkout/quality-assured.svg';
import StarIcon from '@assets/svg/common/star.svg';
import WheelCartIcon from '@assets/svg/common/cart.svg';
import DogSitIcon from '@assets/svg/common/dog-sit.svg';

const allowedIcons = [
    'arrow-left',
    'arrow-right',
    'cart',
    'chevron',
    'cross',
    'customer-service',
    'fast-ship',
    'guarantee',
    'input-arrow-right',
    'logo',
    'minus',
    'paw',
    'plus',
    'profile',
    'quality-assured',
    'star',
    'wheelCart',
    'dog-sit',
] as const;

export type IconName = (typeof allowedIcons)[number];

interface Props extends SVGProps<SVGSVGElement> {
    name: IconName;
    className?: string;
}

type Icons = {
    [name in IconName]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const icons: Icons = {
    'arrow-left': ArrowLeftIcon,
    'arrow-right': ArrowRightIcon,
    'customer-service': CustomerServiceIcon,
    'fast-ship': FastShipIcon,
    'input-arrow-right': InputArrowRightIcon,
    'quality-assured': QualityAssuredIcon,
    cart: CartIcon,
    chevron: ChevronIcon,
    cross: CrossIcon,
    guarantee: GuaranteeIcon,
    logo: LogoIcon,
    minus: MinusIcon,
    paw: PawIcon,
    plus: PlusIcon,
    profile: ProfileIcon,
    star: StarIcon,
    wheelCart: WheelCartIcon,
    'dog-sit': DogSitIcon,
};

const Icon: React.FC<Props> = ({ name, className, ...props }) => {
    const IconByType = icons[name];

    if (!IconByType) return null;

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <IconByType name={name} className={className} {...props} />;
};

export default Icon;
