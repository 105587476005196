import React, { FC } from 'react';

import { Link } from 'gatsby';

import Icon from '@src/components/common/Icon';

const footerLinksSections = [
    {
        sectionTitle: 'Company',
        links: [
            {
                title: 'Our Story',
                to: '/about-us',
            },
            {
                title: 'Order Tracking',
                to: '/',
            },
            {
                title: 'Contact Us',
                to: '/contact-us',
            },
            {
                title: 'Products',
                to: '/shop',
            },
        ],
    },
    {
        sectionTitle: 'Information',
        links: [
            {
                title: 'Refund Policy',
                to: '/faqs/#refund-policy',
            },
            {
                title: 'Shipping & Handling',
                to: '/faqs/#shipping-and-delivery',
            },
            {
                title: 'FAQs',
                to: '/faqs',
            },
        ],
    },
];

const Footer: FC = () => {
    return (
        <footer className='footer-container'>
            <div className='main-footer'>
                <div className='company-info'>
                    <Icon name='logo' className='logo' />
                    <p className='description'>
                        Medipups is a company built for pet owners, by pet owners.
                    </p>
                    <p className='contacts'>
                        <span>support@medipups.com</span>
                        <span>+1 (888) 507-3139</span>
                    </p>
                </div>
                <div className='newsletter'>
                    {/* <h5 className='title'>Newsletter</h5> */}
                    {/* <p className='description'> */}
                    {/*    Join Our Mailing List For Awesome Updates & Unlock Special Discounts! */}
                    {/* </p> */}
                    {/* <div className='input-group'> */}
                    {/*    <input type='text' placeholder='Your Email Address' /> */}
                    {/*    <div className='icon'> */}
                    {/*        <Icon name='input-arrow-right' /> */}
                    {/*    </div> */}
                    {/* </div> */}
                </div>
                <div className='links-sections'>
                    {footerLinksSections.map(({ sectionTitle, links }) => (
                        <div className='links-section' key={sectionTitle}>
                            <p className='section-title'>{sectionTitle}</p>
                            <div className='links'>
                                {links.map(({ title, to }) => (
                                    <Link to={to} key={title} className='section-link'>
                                        {title}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='sub-footer'>
                <span className='company'>{`Medipups © ${new Date().getFullYear()}`}</span>
                <div className='links'>
                    <Link to='/terms-and-conditions' className='sub-footer-link'>
                        Terms & Conditions
                    </Link>
                    <Link to='/privacy-policy' className='sub-footer-link'>
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
