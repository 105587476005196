import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import useLocalStorageState from 'use-local-storage-state';

import Button from '@components/common/Button';

import promoCodeData from '@src/configs/promoCodeData';

const Banner: FC = () => {
    const [bannerIsOpen, setBannerIsOpen] = useLocalStorageState<boolean>('isOpenPromoCodeBanner', {
        defaultValue: true,
    });
    const [hasWindow, setHasWindow] = useState(false);

    useEffect(() => {
        setHasWindow(true);
    }, []);

    const handleCloseBanner = (): void => {
        setBannerIsOpen(!bannerIsOpen);
    };

    return (
        <div className='banner-container'>
            <div
                className={classNames('banner', {
                    showed: bannerIsOpen && hasWindow,
                })}
            >
                <div className='banned-info'>
                    <span className='promo-description'>
                        {promoCodeData.description}&nbsp;
                        <span className='bold'>Use Code:&nbsp;{promoCodeData.code}</span>
                    </span>
                </div>

                <Button icon='cross' color='transparent' onClick={handleCloseBanner} />
            </div>
        </div>
    );
};

export default Banner;
