import React, { Dispatch, FC, SetStateAction } from 'react';

import { CartItems } from '@src/hooks/useCart';

import { Link } from 'gatsby';

type CartItem = {
    index: number;
    setCartItems: Dispatch<SetStateAction<CartItems[]>>;
    cartItems: CartItems[];
} & CartItems;

export const delivery = {
    MONTHLY: 'Monthly (Most common)',
    EVERY_2_MONTHS: '2 Months',
    EVERY_3_MONTHS: '3 Months',
    NONE: 'One time',
};

const CartItem: FC<CartItem> = ({
    product,
    quantity,
    deliveryFrequency,
    setCartItems,
    cartItems,
    index,
}) => {
    const removeItemHandler = (): void => {
        cartItems.splice(index, 1);
        setCartItems(cartItems);
    };

    return (
        <React.Fragment>
            <div className='cart-item'>
                <Link
                    to={`/product/${product.slug}`}
                    className='image-container'
                    style={{ backgroundColor: product.color }}
                >
                    <img
                        alt={product.name}
                        src={product?.images?.quantityImages[quantity - 1]}
                        width={128}
                        height={128}
                    />
                </Link>
                <div className='info-container'>
                    <div className='name'>{product.name}</div>
                    <div className='quantity'>
                        <span className='muted'>Quantity: </span>
                        <span className='text'>{quantity} bottle</span>
                    </div>
                    <div className='deliver-frequency'>
                        <span className='muted'>Delivery Frequency: </span>
                        <span className='text'>{delivery[deliveryFrequency]}</span>
                    </div>

                    <button
                        type='button'
                        className='remove-from-cart muted'
                        onClick={removeItemHandler}
                    >
                        Remove from cart
                    </button>
                </div>
                <div className='price-container'>
                    <span className='quantity'>
                        <span className='muted'>{product.quantity} x </span>
                    </span>
                    <span className='price text-bold'>${product.price}</span>
                </div>
            </div>

            <hr className='separator-line' />
        </React.Fragment>
    );
};

export default CartItem;
