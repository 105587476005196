/* eslint-disable react/jsx-props-no-spreading */
import React, { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames';

import Icon, { IconName } from '@components/common/Icon';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    color?: 'primary' | 'primary-light' | 'link' | 'transparent' | 'edit' | 'disabled';
    icon?: IconName;
    iconClassName?: string;
}

const Button: FC<Props> = ({
    className,
    color = 'primary',
    icon,
    children,
    iconClassName = 'button-icon',
    ...props
}) => {
    return (
        <button
            type='button'
            className={classNames(className, 'btn', {
                [`btn-${color}`]: color,
                'with-icon': !!icon,
            })}
            {...props}
        >
            <span>{children}</span>
            {icon && <Icon name={icon} className={iconClassName} />}
        </button>
    );
};

export default Button;
