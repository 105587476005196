import { Dispatch, SetStateAction, useContext, createContext, useMemo, useCallback } from 'react';

import useLocalStorageState from 'use-local-storage-state';

import { Product, SubscriptionType } from '@helpers/getProduct';

export type CartItems = {
    product: Product & { quantity: number; total: string };
    quantity: number;
    deliveryFrequency: SubscriptionType;
};

interface UseCart {
    toggleCart: () => void;
    isOpen: boolean;
    setCartItems: Dispatch<SetStateAction<CartItems[]>>;
    cartItems: CartItems[];
    subtotal: number;
}

interface ContextProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const CartContext = createContext<ContextProps>({ isOpen: false, setIsOpen: () => {} });

const useCart = (): UseCart => {
    const { isOpen, setIsOpen } = useContext(CartContext);

    const [cartItems, setCartItems] = useLocalStorageState<CartItems[]>('cartItems', {
        defaultValue: [],
    });

    const toggleCart = (): void => {
        setIsOpen(!isOpen);
    };

    const getSubtotal = useCallback(() => {
        return cartItems.reduce(
            (accumulator, currentValue) => parseFloat(currentValue.product.total) + accumulator,
            0,
        );
    }, [cartItems]);

    const subtotal = getSubtotal();

    return { toggleCart, isOpen, cartItems, setCartItems, subtotal };
};

export default useCart;
