/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect, useRef } from 'react';

import { navigate } from 'gatsby';

import classnames from 'classnames';
import Div100vh from 'react-div-100vh';

import Button from '@components/common/Button';
import CartItem from '@components/cart/CartItem';

import useCart from '@src/hooks/useCart';

const Cart: FC = () => {
    const body = useRef<HTMLElement>();

    const { toggleCart, isOpen, cartItems, setCartItems } = useCart();

    const handleClose = (): void => toggleCart();

    const getSubtotal = (): number => {
        return cartItems.reduce(
            (accumulator, currentValue) => parseFloat(currentValue.product.total) + accumulator,
            0,
        );
    };

    const handleCheckout = (): void => {
        handleClose();
        void navigate('/checkout');
    };

    useEffect(() => {
        body.current = document.body;
        body.current.classList.remove('modal-open');

        return () => {
            body?.current?.classList.remove('modal-open');
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            body.current?.classList.add('modal-open');
        } else {
            body.current?.classList.remove('modal-open');
        }

        if (!isOpen) {
            body?.current?.classList.remove('modal-open');
        }
    }, [isOpen]);

    return (
        <React.Fragment>
            <div className={classnames('cart', { 'open-cart': isOpen })}>
                <Div100vh className='cart-container'>
                    <div className='top'>
                        <h3 className='card-title'>Shopping Cart</h3>

                        <Button
                            className='close-btn'
                            icon='cross'
                            color='transparent'
                            iconClassName='close-btn-icon'
                            onClick={handleClose}
                        />
                    </div>
                    <div className='content'>
                        {cartItems.length
                            ? cartItems.map(({ quantity, deliveryFrequency, product }, index) => {
                                  return (
                                      <CartItem
                                          key={`${product?.id}-${index}`}
                                          product={product}
                                          deliveryFrequency={deliveryFrequency}
                                          quantity={quantity}
                                          setCartItems={setCartItems}
                                          cartItems={cartItems}
                                          index={index}
                                      />
                                  );
                              })
                            : 'No products in the cart'}
                    </div>

                    <div className='bottom'>
                        <div className='subtotal'>
                            <p className='text label text-bold'>Subtotal:</p>
                            <p className='text total-sum text-bold'>${getSubtotal().toFixed(2)}</p>
                        </div>
                        <Button
                            disabled={!cartItems.length}
                            onClick={handleCheckout}
                            className='checkout-button'
                            color={!cartItems.length ? 'disabled' : 'primary-light'}
                        >
                            Checkout
                        </Button>
                    </div>
                </Div100vh>
            </div>

            <div className='overlay' onClick={handleClose} />
        </React.Fragment>
    );
};

export default Cart;
